<template>
    <div>
        <div class="mb-2">
            <a-button @click="$router.back()">
                Regresar
            </a-button>
        </div>
        <div class="flex">
            <div class="w-full">
                <form @submit.prevent="update(student)" @keydown="removeError($event.target.id)" @input="removeError($event.target.id)">
                    <h1 class="text-2xl my-4">Actualizar estudiante</h1>
                    <a-card>
                        <div class="flex flex-wrap -mx-2">
                            <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                                <a-input
                                    label="Nombre"
                                    class="mb-4"
                                    :disabled="loading"
                                    id="first_name"
                                    :error="error('first_name', { attribute: 'nombre' })"
                                    v-model.number="student.first_name" />

                                <a-input
                                    label="Apellido"
                                    :disabled="loading"
                                    class="mb-4"
                                    id="last_name"
                                    :error="error('last_name', { attribute: 'apellido' })"
                                    v-model="student.last_name" />

                                <a-input
                                    label="Correo electrónico"
                                    class="mb-4"
                                    type="email"
                                    disabled
                                    id="email"
                                    :error="error('email', { attribute: 'correo electrónico' })"
                                    v-model="student.email" />

                                <a-input
                                    label="Correo electrónico de la institución"
                                    class="mb-4"
                                    type="email"
                                    :disabled="loading"
                                    id="institution_email"
                                    :error="error('institution_email', { attribute: 'correo electrónico' })"
                                    v-model="student.institution_email" />

                                <a-input
                                    label="Cédula"
                                    class="mb-4"
                                    id="id_document"
                                    :disabled="loading"
                                    :error="error('id_document', { attribute: 'cédula' })"
                                    v-model="student.id_document" />

                                <label class="block mb-2 text-gray-700">
                                    Género
                                </label>
                                <v-select
                                    class="mb-4"
                                    :options="[{label: 'Masculino', value: 'male'}, {label: 'Femenino', value: 'female'}]"
                                    :error="error('gender', { attribute: 'género' })"
                                    :reduce="$0 => $0.value"
                                    v-model="student.gender" />

                                <label class="block mb-2 text-gray-700">
                                    Turno
                                </label>
                                <v-select
                                    :disabled="loading"
                                    class="mb-4"
                                    :options="[{label: 'Mañana', value: 'morning'}, {label: 'Tarde', value: 'afternoon'}, {label: 'Mixto', value: 'mixed'}]"
                                    :error="error('schedule_shift', { attribute: 'turno' })"
                                    :reduce="$0 => $0.value"
                                    v-model="student.schedule_shift" />

                                <a-input
                                    label="Dirección"
                                    class="mb-4"
                                    id="address"
                                    :disabled="loading"
                                    :error="error('address', { attribute: 'turno' })"
                                    v-model="student.address" />

                                <a-input
                                    label="Lugar de nacimiento"
                                    class="mb-4"
                                    id="birth_place"
                                    :disabled="loading"
                                    :error="error('birth_place', { attribute: 'lugar de nacimiento' })"
                                    v-model="student.birth_place" />

                                <a-input
                                    label="Fecha de nacimiento"
                                    class="mb-4"
                                    id="birth_date"
                                    type="date"
                                    :disabled="loading"
                                    :error="error('birth_date', { attribute: 'fecha de nacimiento', format: (value) => value, date: (value) => value })"
                                    v-model="student.birth_date" />

                                <a-input
                                    label="Teléfono"
                                    class="mb-4"
                                    id="phone"
                                    :disabled="loading"
                                    :error="error('phone', { attribute: 'teléfono' })"
                                    v-model="student.phone" />

                                <a-input
                                    label="Teléfono celular"
                                    class="mb-4"
                                    id="mobile_phone"
                                    :disabled="loading"
                                    :error="error('mobile_phone', { attribute: 'teléfono celular' })"
                                    v-model="student.mobile_phone" />
                            </div>

                            <div class="w-full md:w-1/2 lg:w-1/2 px-2">

                                <a-input
                                    label="Titulo educativo"
                                    class="mb-4"
                                    :disabled="loading"
                                    id="educational_title"
                                    :error="error('educational_title', { attribute: 'turno' })"
                                    v-model="student.educational_title" />

                                <label class="block mb-2 text-gray-700">
                                    Nivel de estudio
                                </label>
                                <v-select
                                    :disabled="loading"
                                    :options="[{label: 'Media', value: 'medium'}, {label: 'Superior', value: 'superior'}]"
                                    :error="error('educational_background', { attribute: 'turno' })"
                                    :reduce="$0 => $0.value"
                                    class="mb-4"
                                    v-model="student.educational_background" />

                                <a-input
                                    label="Nombre de instituto educativo"
                                    class="mb-4"
                                    :disabled="loading"
                                    id="educational_institution_name"
                                    :error="error('educational_institution_name', { attribute: 'nombre de instituto educativo' })"
                                    v-model="student.educational_institution_name" />

                                <a-input
                                    label="Fecha de graduación"
                                    class="mb-4"
                                    :disabled="loading"
                                    id="educational_graduation_date"
                                    type="date"
                                    :error="error('educational_graduation_date', { attribute: 'fecha de graduación', format: (value) => value, date: (value) => value })"
                                    v-model="student.educational_graduation_date" />

                                <a-input
                                    label="Actividad educativa actual"
                                    class="mb-4"
                                    :disabled="loading"
                                    id="educational_current_activity"
                                    :error="error('educational_current_activity', { attribute: 'actividad educativa actual' })"
                                    v-model="student.educational_current_activity" />

                                <a-input
                                    label="Email del representante"
                                    class="mb-4"
                                    :disabled="loading"
                                    id="representative_email"
                                    :error="error('representative_email', { attribute: 'email del representante' })"
                                    v-model="student.representative_email" />

                                <a-input
                                    label="Nombre del representante"
                                    class="mb-4"
                                    :disabled="loading"
                                    id="representative_name"
                                    :error="error('representative_name', { attribute: 'nombre del representante' })"
                                    v-model="student.representative_name" />

                                <a-input
                                    label="Profesión del representante"
                                    class="mb-4"
                                    :disabled="loading"
                                    id="representative_profession"
                                    :error="error('representative_profession', { attribute: 'profesión del representante' })"
                                    v-model="student.representative_profession" />

                                <a-input
                                    label="Dirección del representante"
                                    class="mb-4"
                                    :disabled="loading"
                                    id="representative_address"
                                    :error="error('representative_address', { attribute: 'dirección del representante' })"
                                    v-model="student.representative_address" />
                            </div>
                        </div>
                    </a-card>
                    <div class="flex space-x-2">
                        <a-button type="submit" :loading="loading">
                            Actualizar cambios
                        </a-button>
                        <a-button outlined @click="$router.back()">
                            Cancelar
                        </a-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
    data: () => ({
        student: {}
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error'
        }),
        ...mapState({
            students: ({ students }) => students.pagination.data,
            currentstudentOnState: ({ students }) => students.current,
            loading: ({ students }) => students.loading,
            loadingPeriods: ({ periods }) => periods.loading,
            loadingSpecialization: ({ specializations }) => specializations.loading,
            periods: ({ periods }) => periods.all,
            specializations: ({ specializations }) => specializations.all,
        })
    },
    methods: {
        ...mapActions({
            fetchStudent: 'students/show',
            fetchPerios: 'periods/index',
            fetchSpecializations: 'specializations/fetchSpecializations',
            update: 'students/update',
            removeError: 'removeError',
        })
    },
    watch: {
        currentstudentOnState(val) {
            this.student = {...val}
        }
    },
    created() {
        const { student } = this.$route.params
        this.fetchPerios({ limit: 0, enabled: true })
        this.fetchSpecializations({ limit: 0 })

        if (student) {

            // const found = this.students.find(students => students.id === parseInt(student))

            // if (found) {
            //     this.students = {...found}
            // } else {
                this.fetchStudent({ id: student, query: {} })
            // }
        }
    }
}
</script>
